<template>
    <div class="createWord">
        <div class="header flex-align-between">
            <span>当前位置：我的字帖 > 编辑字帖</span>
            <div class="back flex-align">
                <img :src="require('../../assets/task/return.png')" @click="goback" />
                <span @click="goback">返回</span>
            </div>
        </div>
        <div class="content flex-align">
            <div class="left">
                <span class="title">{{$route.query.type==1?'课堂写字单':$route.query.type==2?'篇章字帖':'生字字帖'}}</span>
                <div class="preview">
                    <div class="preview_wrap flex-column-center">
                        <span class="preview1">预览</span>
                        <span>(第一页，完成编辑课查看完整字帖)</span>
                        <div class="imgWrap">
                            <img src="@/assets/copybook/type_word2.png" v-if="$route.query.type==1||detail.type==1">
                            <img src="@/assets/copybook/type_mi.jpg"
                                v-if="gridType==2&&$route.query.type==2||gridType==2&&detail.type==2">
                            <img src="@/assets/copybook/type_tian.jpg"
                                v-if="gridType==1&&$route.query.type==2||gridType==1&&detail.type==2">
                            <img src="@/assets/copybook/type_empty.jpg"
                                v-if="gridType==0&&$route.query.type==2||gridType==0&&detail.type==2">
                            <img src="@/assets/copybook/type_mi.jpg"
                                v-if="gridType==2&&$route.query.type==4||gridType==2&&detail.type==4">
                            <img src="@/assets/copybook/type_tian.jpg"
                                v-if="gridType==1&&$route.query.type==4||gridType==1&&detail.type==4">
                            <img src="@/assets/copybook/type_empty.jpg"
                                v-if="gridType==0&&$route.query.type==4||gridType==0&&detail.type==4">
                            <template v-if="$route.query.type==2||detail.type==2">
                                <div class="items" v-for="(item,index) in wordList" :key="index"
                                    :style="{top:points.points[index][1]*0.185/216+'rem',left:points.points[index][0]*0.1833333333/216+'rem'}">
                                    {{item}}</div>
                            </template>
                            <template v-if="$route.query.type==4||detail.type==4">
                                <div class="items" v-for="(item,index) in wordList" :key="index"
                                    :style="{top:points.points[index][1]*0.1833333333/216+'rem',left:points.points[index][0]*0.1833333333/216+'rem'}">
                                    {{item}}</div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right">
                <span class="title">字帖内容</span>
                <div class="wordContent flex-column">
                    <div class="line"></div>
                    <span class="wordName">字帖名称:</span>
                    <el-input placeholder="请输入内容" v-model="name" clearable>
                    </el-input>
                    <span class="span2">绑定学生:</span>
                    <div class="bindSchool flex-align">
                        <!-- <el-input v-model="schoolVal" @input="changeSchool" placeholder="请输入学校名称"></el-input>  -->
                        <!-- <el-select v-model="bindGrade" placeholder="请选择年级" @change="changeClass">
                            <el-option v-for="(item,index) in gradeList" :key="index" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select> -->
                    </div>
                    <div class="bindStu flex-align">
                        <el-select v-model="bindStu" placeholder="请选择班级" @change="changeClass">
                            <el-option v-for="(item,index) in classList" :key="index" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                        <span>(已选{{bindStuList.length||0}}人)</span>
                        <div class="selectStu flex-align" @click="chooseStu(bindStu)">
                            <img src="@/assets/copybook/add4.png" alt="">
                            选择学生
                        </div>
                    </div>
                    <div class="words flex-align-between">
                        <span>字帖内容:</span>
                        <div class="delete flex-align" @click="clear">
                            <img src="@/assets/copybook/delete.png" alt="">
                            清空
                        </div>
                    </div>
                    <el-input type="textarea" :rows="3" placeholder="请输入内容" maxlength="500" show-word-limit
                        v-model="textarea">
                    </el-input>
                    <div class="fastInput flex-align">
                        <span>快捷输入:</span>
                        <span class="grade" v-for="(item,index) in fastList" :key="index"
                            @click="chooseWrod(item.id,item.name)">{{item.name}}、</span>
                    </div>
                    <span class="span3">格子类型:</span>
                    <div class="imgs flex-align">
                        <img :src="gridType==1?require('@/assets/copybook/tian_active.png'):require('@/assets/copybook/mi3.png')"
                            @click="chooseGrid(1)">
                        <img :src="gridType==2?require('@/assets/copybook/mi_active.png'):require('@/assets/copybook/mi1.png')"
                            @click="chooseGrid(2)">
                        <img :src="gridType==0?require('@/assets/copybook/empty_active.png'):require('@/assets/copybook/mi2.png')"
                            @click="chooseGrid(0)">
                    </div>
                    <div class="make" :class="{'active':isMake,'disabled':disabled}" @click="create">生成字帖</div>
                </div>
            </div>
        </div>
        <!-- 生成字帖弹窗 -->
        <el-dialog custom-class="myDialog" :visible='dialogVisible' :before-close="handleClose" center title="生成字帖">
            <div class="tipWrap flex-column-center">
                <span>正在生成字帖，这个过程需要几分钟...</span>
                <span>您可先进行其他操作,字帖生成后会保存在</span>
                <span>"我创建的"中</span>
                <div class="check" @click="check">去查看</div>
            </div>
        </el-dialog>
        <!-- 绑定学生弹窗 -->
        <el-dialog custom-class="bindStuDialog" :visible='dialogVisible2' :before-close="handleClose" center
            title="绑定学生">
            <div class="bindWrap flex-align-between">
                <span>{{schoolName}}-{{className}}</span>
                <el-input placeholder="请输入学生姓名" v-model="searchName" @input="searchStu" suffix-icon="el-icon-search">
                </el-input>
            </div>
            <el-table :data="tableData" stripe style="width: 100%" @selection-change="handleSelectionChange">
                <el-table-column width="150" align="center">
                    <template slot="header" slot-scope="scope">
                        <el-checkbox v-model="allSelect" @change="changeAll">全选</el-checkbox>
                    </template>
                    <template slot-scope="scope">
                        <el-checkbox v-model="scope.row.select"
                            @change="selectStu(scope.row.id,scope.$index,scope.row.select)">
                        </el-checkbox>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="姓名" width="150" align="center">
                </el-table-column>
                <el-table-column prop="className" label="班级" align="center">
                </el-table-column>
            </el-table>
            <div class="pageWrap flex-align-around">
                <el-pagination background layout="prev, pager, next,total ,jumper" :page-size='5' :total="total"
                    @current-change="handleCurrentChange">
                </el-pagination>
            </div>
            <div class="confirm" @click="handleClose">
                确定
            </div>
        </el-dialog>
        <!-- 快捷输入弹窗 -->
        <el-dialog custom-class="fastInputDialog" :visible='dialogVisible3' :before-close="handleClose" center
            :title="currentTitle">
            <div class="wordContent flex-layout">
                <div class="fast_left">
                    <div class="write_wrap flex-align" @click="changeType(1)">
                        <div class="wirte" :class="{'active':type==1}">午写字单
                            <img src="@/assets/copybook/triangle.png" alt="" v-if="type==1">
                        </div>
                    </div>
                    <div class="write_wrap flex-align" @click="changeType(2)">
                        <div class="wirte" :class="{'active':type==2}">单元字帖
                            <img src="@/assets/copybook/triangle.png" alt="" v-if="type==2">
                        </div>
                    </div>
                </div>
                <div class="fast_right">
                    <div class="cell flex-align-between">
                        <span>{{type==1?'选择周':'选择单元'}}:</span>
                        <el-select v-model="value" placeholder="请选择" @change='changeValue'>
                            <el-option v-for="(item,index) in options2" :key="index"
                                :label="type==1?item.weekGroupName:item.name"
                                :value="type==1?item.weekGroup:item.unitId">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="chooseWord flex-align-between">
                        <span>选择生字:</span>
                        <el-checkbox v-if="type==2" v-model="checked">全选</el-checkbox>
                    </div>
                    <div class="wordWrap">
                        <div class="classItem flex-layout" v-if="type==2">
                            <template v-if="words.length>0">
                                <div class="cellItem" v-for="(item,index) in words" :key="index">
                                    <el-checkbox v-model="checked">第一课</el-checkbox>
                                    <div class="words flex-layout">
                                        <div class="wordItem" v-for="items in item.wordList" :key="items.wordId">
                                            <img src="@/assets/copybook/mi1.png" alt="">
                                            <span>{{items.wordName}}</span>
                                            <img class="active" src="@/assets/copybook/active.png" alt="">
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <div class="empty flex-column-center">
                                <img src="@/assets/empty.png" />
                                <span>期待同学们上传作品~</span>
                            </div>
                        </div>
                        <div class="classItem2 flex-column" v-if="type==1">
                            <div class="weekItem flex-align" v-for="(item,index) in words" :key="index">
                                <el-checkbox v-model="item.checked" @change="changeWeek(index)">
                                    {{item.weekName}}</el-checkbox>
                                <div class="words flex-layout" v-for="(items,childIndex) in item.wordList"
                                    :key="items.wordId">
                                    <div class="wordItem active" @click="chooseWord(index,childIndex)">
                                        <img
                                            :src="items.checked?require('@/assets/copybook/mi_green.png'):require('@/assets/copybook/mi1.png')">
                                        <span :class="{'active':items.checked}">{{items.wordName}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="btn" @click="confirm">确定</div>
                </div>
            </div>

        </el-dialog>
    </div>
</template>

<script>
    import points from './coord.json'
    export default {
        data() {
            return {
                name: '',
                searchName: '',
                options2: [],
                checked: true,
                bindStu: '',
                value: '',
                textarea: '',
                dialogVisible: false,
                dialogVisible2: false,
                dialogVisible3: false,
                originData: [], // 学生列表原始数据
                tableData: [], // 学生列表分页数据
                type: 1,
                fastList: [],
                classList: [],
                className: '',
                points: points,
                gridType: 1,
                page: 1,
                limit: 5,
                total: null,
                // selectList: [],
                currnetGrade: null,
                words: [],
                detail: {
                    type: -1
                },
                bindStuList: [], //绑定学生数组
                bindStuListCopy: [], // 绑定学生数组备份
                allSelect: true,
                bindWordList: [], //快捷输入选字数组
                disabled: false,
                schoolName: '',
                currentTitle: '',
                schoolVal: '',
                bindGrade: '',
                gradeList: []
            }
        },
        computed: {
            wordList() {
                if (this.$route.query.type == 1) {
                    // 课堂写字单
                    let arr = this.textarea.split("")
                    return arr
                } else if (this.$route.query.type == 2) {
                    // 篇章字帖
                    let arr = this.textarea.split("");
                    // 将arr转为二维数组
                    let arr2 = [];
                    for (var i = 0; i < arr.length; i += 11) {
                        arr2.push(arr.slice(i, i + 11))
                    }
                    // 追加空行(隔一行显示)
                    arr2.map(item => {
                        item.push('', '', '', '', '', '', '', '', '', '', '')
                    })
                    // 将二维数组转一维数组
                    return [].concat.apply([], arr2).slice(0, 132)
                } else {
                    // 生字字帖
                    let arr = this.textarea.split("");
                    let arr2 = [];
                    arr.map(item => {
                        let arr = [];
                        arr.push(item);
                        arr2.push(arr)
                    });
                    // 追加空格
                    arr2.map(item => {
                        item.push('', '', '', '', '', '', '', '', '', '')
                    });
                    // 将二维数组转一维数组
                    return [].concat.apply([], arr2).slice(0, 132)
                }
            },
            isMake() {
                if (this.name && this.textarea) {
                    return true
                } else {
                    return false
                }
            }
        },
        mounted() {
            this.getGrade();
            this.getClassList();
            this.getUserInfo();
            if (this.$route.query.isEdit == 'true') {
                // 编辑模式
                this.copyBookDetail()
            }
        },
        methods: {
            goback() {
                this.$router.go(-1);
                this.$route.name == 'curriculum' ? sessionStorage.removeItem("type") : '';
            },
            clear() {
                this.textarea = ''
            },
            handleClose() {
                this.dialogVisible = false;
                this.dialogVisible2 = false;
                this.dialogVisible3 = false;
                this.disabled = false;
            },
            create() {
                if (this.disabled) {
                    return
                }
                console.log('ccccc');
                if (!this.name) {
                    this.$message({
                        message: '字帖名称不能为空',
                        type: 'warning'
                    });
                    return
                }
                if (!this.bindStu) {
                    this.$message({
                        message: '请先选择班级',
                        type: 'warning'
                    });
                    return
                }
                if (!this.textarea) {
                    this.$message({
                        message: '字帖内容不能为空',
                        type: 'warning'
                    });
                    return
                }
                this.disabled = true;
                let data = {
                    // transcriptId: this.$route.query.transcriptId ? this.$route.query.transcriptId : null,
                    name: this.name,
                    content: this.textarea,
                    type: this.$route.query.type || this.detail.type,
                    style: this.gridType,
                    users: this.bindStuList,
                    modelId: this.$route.query.copyType == 'defaultMode' ? '' : this.$route.query.modelId || '',
                    classId: this.bindStu
                }
                this.$Api.CopyBook.createCopyBook(data)
                    .then(res => {
                        console.log('创建字帖成功', res);
                        this.dialogVisible = true;
                    })
                    .catch(err => {
                        console.log('创建字帖失败', err);
                        this.$message.error('创建失败，请联系管理员');
                    })
            },
            check() {
                this.handleClose();
                // this.$router.go(-1)
                let query = {
                    fromPath: 'createWord'
                }
                this.$router.push({
                    path: '/copybook',
                    query
                })
            },
            changeClass(val) {
                let index = this.classList.findIndex(item => {
                    return item.id == val
                })
                this.className = this.classList[index].name;
                // 选择班级默认选中该班级全部学生
                let data = {
                    classId: this.bindStu,
                    page: 1,
                    limit: 999,
                }
                this.$Api.CopyBook.getClassStu(data)
                    .then(res => {
                        console.log('学员列表', res);
                        let obj = {
                            id: Number(sessionStorage.getItem("teacherId")),
                            name: sessionStorage.getItem("teacherName")
                        }
                        res.data.list.unshift(obj)
                        res.data.list.map(item => {
                            item.className = this.className;
                            item.select = true;
                            this.bindStuList.push(item.id);
                            this.bindStuListCopy.push(item.id);
                        })
                        this.originData = res.data.list;
                        this.tableData = this.originData.slice(0, 5);
                        this.total = res.data.totalCount;
                        console.log('对比结果--', this.bindStuList == this.bindStuListCopy)
                    })
                    .catch(err => {
                        console.log('获取学员失败--', err)
                    })
            },
            chooseWrod(id, name) {
                console.log('快捷输入id', id);
                this.currnetGrade = id;
                this.currentTitle = name;
                this.getWeekList();
                this.dialogVisible3 = true;
            },
            chooseStu(classId) {
                if (!classId) {
                    this.$message({
                        message: '请先选择班级',
                        type: 'warning'
                    });
                    return
                }
                this.searchName = '';
                this.getStuList();
                this.dialogVisible2 = true
            },
            getStuList() {
                // 根据班级id获取学生
                // 根据本地数据originData手动本地分页不走接口
                if (this.searchName) {
                    this.tableData = this.originData.filter(item => {
                        return item.name == this.searchName
                    });
                    this.total = this.tableData.length;
                } else {
                    this.tableData = this.originData.slice((this.page - 1) * 5, this.page * 5);
                    this.total = this.originData.length
                }
            },
            changeType(type) {
                // type1-午写字单  type2-单元字帖
                this.type = type;
                this.options2 = [];
                this.words = [];
                this.value = '';
                if (type == 1) {
                    this.getWeekList()
                } else {
                    this.getCellList()
                }
            },
            // 获取快捷输入班级列表
            getGrade() {
                this.$Api.CopyBook.getGrade()
                    .then(res => {
                        // console.log(res);
                        this.fastList = res.data
                    })
                    .catch(err => {
                        console.log('获取年级失败', err)
                    })
            },
            // 快捷输入弹窗 周列表
            async getWeekList() {
                let data = {
                    gradeId: this.currnetGrade
                }
                let resData = await this.$Api.Myclass.getWeekIdsByGradeId(data);
                // console.log('周列表', resData);
                this.options2 = resData.data;
                this.value = resData.data[0].weekGroup;
                this.changeValue(resData.data[0].weekGroup)
            },
            // 快捷输入弹窗 单元列表
            async getCellList() {
                let data = {
                    termId: this.currnetGrade
                }
                let resData = await this.$Api.Myclass.getUnittable(data);
                this.options2 = resData.data;
                this.value = resData.data[0].unitId;
                this.changeValue(resData.data[0].unitId)
                // console.log(resData)
            },
            // 选择周、单元
            async changeValue(val) {
                // console.log(val);
                this.words = [];
                //根据周、单元获取对应的字
                if (this.type == 1) {
                    let data = {
                        gradeId: this.currnetGrade,
                        weekGroupId: val
                    }
                    let resData = await this.$Api.Myclass.getWordsVoList(data);
                    console.log(resData);
                    resData.checked = false;
                    resData.data.map(item => {
                        item.wordList.map(items => {
                            items.checked = false
                        })
                    })
                    this.words = resData.data;

                } else {
                    let data = {
                        gradeId: this.currnetGrade,
                        // classId: this.classId,
                        unitId: val,
                    }
                    let resData = await this.$Api.Myclass.getClassWordsVoList(data);
                    console.log(resData)
                }

            },
            // 获取绑定学生选择班级列表
            getClassList() {
                this.$Api.CopyBook.getClassList()
                    .then(res => {
                        // console.log(res)
                        this.classList = res.data;
                    })
                    .catch(err => {
                        console.log('获取班级失败', err)
                    })
            },
            // 选择格子类型
            chooseGrid(type) {
                this.gridType = type
            },
            handleCurrentChange(page) {
                console.log('当前页--', page)
                this.page = page;
                this.getStuList()
            },
            handleSelectionChange(selection) {
                console.log('selection---', selection);
                // selection.map(item => {
                //     this.selectList.push(item)
                // })
                // this.selectList = [...new Set(this.selectList.map(item => {
                //     return JSON.stringify(item)
                // }))].map(item => {
                //     return JSON.parse(item)
                // });
                // console.log('去重后的数组--', this.selectList);
            },
            searchStu(name) {
                if (name.trim()) {
                    this.getStuList()
                } else {
                    this.tableData = this.originData.slice(0, 5);
                    this.total = this.originData.length
                }
            },
            // 选中/取消选中学员
            selectStu(stuid, index, status) {
                console.log(stuid, index, status);
                if (!status) {
                    // 取消选中
                    this.allSelect = false;
                    this.bindStuList.splice(index, 1)
                } else {
                    // 选中
                    this.bindStuList.push(stuid);
                    if (this.bindStuList.length == this.originData.length) {
                        this.allSelect = true
                    }
                }
            },
            // 全选/全不选学生
            changeAll(val) {
                console.log(val);
                if (val) {
                    // 这里注意需要进行深拷贝
                    this.bindStuList = JSON.parse(JSON.stringify(this.bindStuListCopy));
                    // 添加每个学生的选中状态
                    this.originData.map(item => {
                        item.select = true;
                    })
                } else {
                    this.bindStuList = [];
                    // 取消每个学生的选中状态
                    this.originData.map(item => {
                        item.select = false;
                    })
                }
            },
            // 全选/全不选周
            changeWeek(index) {
                console.log(this.words[index].checked);
                if (this.words[index].checked) {
                    this.words[index].wordList.map(item => {
                        item.checked = true;
                        this.addBindWordList(item.wordName, 'add')
                    })
                } else {
                    this.words[index].wordList.map(item => {
                        item.checked = false;
                        this.addBindWordList(item.wordName, 'delete')
                    })
                }
            },
            // 快捷输入弹窗选择字
            chooseWord(index, childIndex) {
                // console.log('父index--',index,'子index',childIndex);
                this.$set(this.words[index].wordList, childIndex, {
                    ...this.words[index].wordList[childIndex],
                    checked: !this.words[index].wordList[childIndex].checked
                })
                // 判断当前周数组中是否全选
                for (var i = 0; i < this.words[index].wordList.length; i++) {
                    if (!this.words[index].wordList[i].checked) {
                        this.words[index].checked = false;
                        break
                    } else {
                        this.words[index].checked = true;
                    }
                }
                // 将当前选字添加到 bindWordList(快捷输入选字数组)
                if (this.words[index].wordList[childIndex].checked) {
                    this.addBindWordList(this.words[index].wordList[childIndex].wordName, 'add')
                } else {
                    this.addBindWordList(this.words[index].wordList[childIndex].wordName, 'delete')
                }

            },
            // 添加字
            addBindWordList(word, type) {
                // type -- add添加,delete删除
                if (type == 'add') {
                    if (this.bindWordList.indexOf(word) < 0) {
                        this.bindWordList.push(word)
                    }
                } else {
                    let index = this.bindWordList.indexOf(word);
                    this.bindWordList.splice(index, 1)
                }

            },
            // 快捷输入确认按钮
            confirm() {
                this.dialogVisible3 = false;
                this.textarea = this.bindWordList.join('')
            },
            // 获取用户信息
            async getUserInfo() {
                let resData = await this.$Api.Home.getUserInfo();
                // console.log('resData', resData);
                this.schoolName = resData.data.schoolName
            },
            // 管理员输入学校名称
            changeSchool(val) {
                console.log(val);
                if(val.trim()){
                    
                }
            },
            // 根据学校名称获取年级
            getGradeList(schoolId){
                console.log('学习id:',schoolId)
            },
            // 根据年级获取班级列表
            getClassListByGrade(gradeId){
                console.log('年级Id:',gradeId)
            },
            //  ---------- 编辑字帖 ----------
            copyBookDetail() {
                let data = {
                    modelId: this.$route.query.modelId
                }
                this.$Api.CopyBook.copyBookDetail(data)
                    .then(res => {
                        console.log(res);
                        this.detail = res.data;
                        this.name = res.data.name;
                        this.textarea = res.data.content;
                        this.gridType = res.data.style;
                        this.bindStuList = res.data.selectUsers || [];
                        this.bindStu = res.data.selectClassId
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },


        },
    }
</script>
<style scoped lang='less'>
    @import "./less/createWord.less";
</style>